import Bugsnag from '@bugsnag/js';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from 'common/store/types';
import { apiRootUrl } from 'common/utils/network/endpoints';

const baseQuery = fetchBaseQuery({
  baseUrl: apiRootUrl,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;

    if (token) {
      headers.set('authorization', `Bearer ${token.token}`);
    }

    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json, text/plain, */*');

    return headers;
  },
  fetchFn: async (input, init) => {
    const response = await fetch(input, init);

    // notify bugsnag if response status is other than 200 or 422
    if (response.status !== 200 && response.status !== 422) {
      Bugsnag.notify(new Error(`Response status: ${response.status}: ${response.statusText}`));
    }

    return response;
  },
});

export default baseQuery;
